.LoginSection {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    /* background: rgb(39, 166, 224); */
    /* background:url(../image/congrations.jpg) no-repeat;
    background-size:30%; */
  }
  
  .formbox {
    position: relative;
    width: 450px;
    height: 550px;
    background: transparent;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .LoginHeaderName {
    font-size: 1.6em;
    color: #fff;
    text-align: center;
    font-style: oblique;
  }

  .Htag{
    text-align: center;
    font-size: 3em;
  }
  

  
  
  
  