.LoginSection {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    /* background: rgb(39, 166, 224); */
    background:url(../image/zurag2.jpg);
  }
  
  .form-box {
    position: relative;
    width: 450px;
    height: 550px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .LoginHeaderName {
    font-size: 1.6em;
    color: #fff;
    text-align: center;
  }
  
  .inputbox {
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 1px solid #ffdede;
  }
  
  .inputbox label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
  }
  
  input:focus~label,
  input:valid~label {
    top: -5px;
  }
  
  .inputbox input {
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0 35px 0 5px;
    color: #fff;
  }
  
  .inputbox i {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 13px;
  }
  
  .SignIn{
   width: 40vh;
   text-align: center;
   margin-left: 28vh;
   height: 5vh;
   border-radius:15px;
   margin: 10px;
  }
  .login{
    margin-left:18px;
  }
  
  
  